import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import homePageCollage from '../images/home-page/home-page-collage.webp'

const LetterFromJohnPage = ({ location }) => (
  <Layout>
    <Seo location={location} title="A Letter From John" />

    <div className="bg-white">
      <div className="max-w-5xl mx-auto py-5 px-4 sm:py-5 sm:px-6 lg:px-8">
        <div className="pt-5 pb-4 text-center">
          <p className="text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            A Letter From John
          </p>
        </div>
        
        <img src={homePageCollage} />

        <div className="text-2xl">
          <br /><a href="#english">English &raquo;</a><br />
          <a href="#espanol">Español &raquo;</a><br />
          <a href="#portugues">Português &raquo;</a><br /><br />
        </div>

        <div className="letter-section" id="english">
          <h2><strong>English</strong></h2>
          <p>8/27/2023</p>
          <p>
            Dear Neighbor,
          </p>
          <p>
            In the event that you have not heard my story, I'm one of the candidates running to succeed Congressman Cicilline. <strong>In a nutshell:</strong> I'm a City Councilor on the East Side of Providence and I'm a teacher with a unique story that's running for Congress to put people first.
          </p>
          <p>
            <strong>As a resident of the First Congressional District for nearly 30 years, I know all too well the struggles our community faces:</strong> the rising cost of living, housing, and healthcare, and struggling K-12 public schools that are not benefiting the hardworking families and seniors of our community. People are worried about education, they're worried about our economy, they're worried about healthcare. They're worried about reproductive rights, they're worried about our climate crisis. People also want a chance to succeed, an opportunity to have a decent quality of life, and good neighborhoods to live in and to provide for their families.
          </p>
          <p>
            <strong>Washington hasn't been working for us. We need a warrior in Washington that's going to stand up for working families and that's why I'm in this fight.</strong>
          </p>
          <p>
            This work is not theoretical for me. It's personal to me: My mother's parents died when she was a kid. She came to this country with nothing to her name but wanted a better life for her children. When I was twelve, my mom begged then-Mayor Cicilline to allow me to go to the best public middle school in Providence, which changed my trajectory forever. When we couldn't afford a car, she rode the RIPTA bus with me to and from school to make sure I got the best education. 
          </p>
          <p>
            I attended Providence Public Schools from K-8 and the Wheeler School for high school. Despite growing up in poverty in a single-parent household, my unwavering resilience and passion for learning eventually led me to graduate with a <strong>B.A. from Brown University and a master's degree from Brown University</strong>.
          </p>
          <p>
            <strong>The story of struggle and resilience here in Rhode Island is my story. My lived experience is also why I truly believe your ZIP code shouldn't determine your destiny. It's also why I believe everyone matters and everyone counts.</strong>
          </p>
          <p>
            <strong>As a two-term City Councilman,</strong> representing the neighborhoods of the East Side and Downtown in the City of Providence, which is the fastest growing economic and residential hub in the state &mdash; I've been responsive, reliable, and effective. I hold monthly town halls, <a href="/issues/improve-quality-of-life/plan">authored a robust quality of life plan</a>, have <strong>resolved thousands and thousands of constituent service issues</strong>, and have sent out nearly 3 million emails to my constituents (here's an example of my neighborhood <a href="https://us4.campaign-archive.com/?u=627d403ab5671dae64834341c&id=551db3ddfe" target="_blank">newsletter</a>, which gives you insight into my leadership/complexity of my work). <strong>I've authored and passed countless pieces of legislation</strong> related to the issues we care about, and similar to Congressman Cicilline, who went from the municipal level to the federal level, <strong>I'm ready to bring my community roots and energy to Washington</strong>.
          </p>
          <p>
            <strong>I'm also a passionate teacher at the Wheeler School in Providence and I have been a teacher for nearly the last decade.</strong> I'm compelled to run because I'm tired of having to teach my students about active shooter drills instead of multiplication and division. I also want all kids to succeed and to be able to have a future where they can have clean water and air to breathe, can afford housing, and have robust economic opportunities in this state.
          </p>
          <p>
            The reason why I advocate tooth and nail for my constituents is because this work isn't theoretical for me. As a person who grew up in poverty, I know what it feels like to go to bed hungry. As someone who has dealt with discrimination, I know what that feels like, too, and I just want a brighter and equitable future for our kids, families, and seniors.
          </p>
          <p>
            <strong>I also believe that power, money, and influence shouldn't be a prerequisite to being able to meaningfully serve our community in Congress.</strong>
          </p>
          <p>
            To realize the vision that we want for Rhode Island, we need a Congressperson who will prioritize the needs of our community and put community first over that of special interests. <strong>Someone who will fight for Social Security and Medicare. Someone who will fight for the best high-quality constituent services. Someone who will fight for Universal Pre-K, Universal Childcare</strong>, and <strong>a quality K-12 public education</strong> in the state. <strong>Someone who will fight for ambitious legislation to end our climate crisis. Someone who will stand up to the extremists that try to divide us. We need a Congressperson who can bring people together to solve problems and move Rhode Island forward. We need a Congressperson who is ready on day 1 and I'm that person.</strong>
          </p>
          <p>
            <strong>Our path to victory:</strong> Our campaign is deeply rooted in the community. I believe in staying above the fray, putting people over politics, bringing people together, and putting people first. We have a great, experienced team that has won over 50+ Congressional races across the country. Read about them <a href="https://us4.campaign-archive.com/?u=627d403ab5671dae64834341c&id=d61951a3a6" target="_blank">here</a>. <strong>I've won elections before in one of the most competitive districts on the East Side despite being counted out, outspent, and outraised 4 to 1. In this race, we have a strong base of support and we've raised over $150K via small, grassroots in-district supporters from all over the state</strong> (we're not accepting any Super PAC money, corporate PAC money, or fossil fuel money, so we are solely beholden to the people and can fight to hold big oil, gun lobby, and big pharma accountable). Our story/message resonates with a broad, diverse coalition of residents/voters who want to send an effective legislator and different kind of voice to Congress.
          </p>
          <p>
            With the election upon us, I look forward to connecting with you and continuing to hear your hopes, dreams, thoughts, and concerns. Please don't hesitate to contact me at 401-227-5056 or <a href="mailto:info@johngforcongress.com">info@johngforcongress.com</a> with any questions or concerns you have. It would be my greatest honor to have your support.
          </p>
          <p>
            <strong>Please note that you can now vote at your Town/City Hall and I ask for your consideration and your support.  I hope I can earn your vote.</strong>
          </p>
          <p>
            Thank you so much,<br />
            John Goncalves
          </p>
          <p>
            <a href="/">https://www.johngforcongress.com/</a>
          </p>
          <p>
            <a href="https://secure.actblue.com/donate/john-goncalves-website-donate?refcode=website-header-nav-desktop-tablet" target="_blank"><strong>Please Make A Contribution</strong></a><br />
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSeOc1a0EdyhHPIxRUrRYcLojn9dBx1UFwAUhj7fF8Hic3ow2w/viewform" target="_blank"><strong>Request A Yard Sign</strong></a><br />
            <a href="https://www.youtube.com/watch?v=4aX1kc4sEBs" target="_blank">Campaign Video</a><br />
            <a href="https://www.instagram.com/johngpvd/" target="_blank">Please Follow Us On Instagram</a><br />
            Please Follow Us On <a href="https://twitter.com/johngpvd" target="_blank">Twitter</a>/<a href="https://www.facebook.com/JohnGForCongress" target="_blank">Facebook</a>
          </p>
          <p>
            (This letter is written in Spanish and Portuguese below if you prefer to read it in those languages. Esta carta está escrita en español y portugués a continuación si prefiere leerla en esos idiomas. Esta carta está escrita em espanhol e português abaixo, se você preferir lê-la nesses idiomas.)
          </p>
		    </div>

        <div className="letter-section" id="espanol">
          <h2><strong>Español</strong></h2>
          <p>8/27/2023</p>
          <p>
            Estimado Neighbor,
          </p>
          <p>
            En caso de que no haya escuchado mi historia, soy uno de los candidatos que se postulan para suceder al congresista Cicilline. <strong>En pocas palabras:</strong> soy concejal de la ciudad en el lado este de Providence y un maestro con una historia única que se postula para que el Congreso ponga a las personas primero.
          </p>
          <p>
            <strong>Como residente del Primer Distrito del Congreso durante casi 30 años, conozco muy bien las luchas que enfrenta nuestra comunidad:</strong> el aumento del costo de la vida, la vivienda y la atención médica, y las escuelas públicas K-12 en dificultades que no benefician a las familias trabajadoras y adultos mayores de nuestra comunidad. La gente está preocupada por la educación, está preocupada por nuestra economía, está preocupada por la atención médica. Están preocupados por los derechos reproductivos, están preocupados por nuestra crisis climática. La gente también quiere tener la oportunidad de tener éxito, la oportunidad de tener una calidad de vida decente y buenos vecindarios para vivir y mantener a sus familias.
          </p>
          <p>
            <strong>Washington no ha estado trabajando para nosotros. Necesitamos un guerrero en Washington que defienda a las familias trabajadoras y por eso estoy en esta lucha.</strong>
          </p>
          <p>
            Este trabajo no es teórico para mí. Es personal para mí: los padres de mi madre murieron cuando ella era una niña. Llegó a este país sin nada a su nombre pero quería una vida mejor para sus hijos. Cuando tenía doce años, mi madre le rogó al entonces alcalde Cicilline que me permitiera ir a la mejor escuela secundaria pública de Providence, lo que cambió mi trayectoria para siempre. Cuando no podíamos pagar un automóvil, me acompañaba en el autobús RIPTA de ida y vuelta a la escuela para asegurarse de que obtuviera la mejor educación.
          </p>
          <p>
            Asistí a las Escuelas Públicas de Providence de K-8 y la Escuela Wheeler para la escuela secundaria. A pesar de haber crecido en la pobreza en un hogar monoparental, la resiliencia inquebrantable y la pasión por aprender finalmente me llevaron a graduarme con un <strong>B.A. de la Universidad de Brown y una maestría de la Universidad de Brown</strong>.
          </p>
          <p>
            <strong>La historia de lucha y resiliencia aquí en Rhode Island es mi historia. Mi experiencia vivida también es la razón por la que realmente creo que su código postal no debería determinar su destino. También es por eso que creo que todos importan y todos cuentan.</strong>
          </p>
          <p>
            <strong>Como concejal de la ciudad durante dos mandatos,</strong> en representación de los vecindarios de East Side y Downtown en la ciudad de Providence, que es el centro económico y residencial de más rápido crecimiento en el estado &mdash; he sido receptivo, confiable, y eficaz. Realizo ayuntamientos mensuales, <a href="/issues/improve-quality-of-life/plan">redacté un sólido plan de calidad de vida</a>, <strong>resolví miles y miles de problemas de servicio a los electores</strong>, y envié casi 3 millones de correos electrónicos a mis electores (aquí hay un ejemplo de mi <a href="https://us4.campaign-archive.com/?u=627d403ab5671dae64834341c&id=551db3ddfe" target="_blank">boletín de vecindario</a>, que le da una idea de mi liderazgo /complejidad de mi trabajo). <strong>He escrito y aprobado innumerables leyes</strong> relacionadas con los temas que nos preocupan y, al igual que el congresista Cicilline, que pasó del nivel municipal al federal, <strong>estoy listo para llevar mis raíces comunitarias y mi energía a Washington</strong>.
          </p>
          <p>
            <strong>También soy un maestro apasionado en la Escuela Wheeler en Providence y he sido maestro durante casi la última década.</strong> Me veo obligado a correr porque estoy cansado de tener que enseñar a mis alumnos sobre ejercicios de tiro activo en lugar de multiplicación y división. También quiero que todos los niños tengan éxito y puedan tener un futuro en el que puedan tener agua limpia y aire para respirar, puedan pagar una vivienda y tengan sólidas oportunidades económicas en este estado.
          </p>
          <p>
            La razón por la que defiendo con uñas y dientes a mis electores es porque este trabajo no es teórico para mí. Como una persona que creció en la pobreza, sé lo que se siente irse a la cama con hambre. Como alguien que ha lidiado con la discriminación, también sé lo que se siente y solo quiero un futuro más brillante y equitativo para nuestros niños, familias, y personas mayores.
          </p>
          <p>
            <strong>También creo que el poder, el dinero y la influencia no deberían ser un requisito previo para poder servir de manera significativa a nuestra comunidad en el Congreso.</strong>
          </p>
          <p>
            Para hacer realidad la visión que queremos para Rhode Island, necesitamos un congresista que priorice las necesidades de nuestra comunidad y ponga a la comunidad primero sobre los intereses especiales. <strong>Alguien que luchará por el Seguro Social y Medicare. Alguien que luchará por los mejores servicios de alta calidad para los constituyentes. Alguien que luche por Universal Pre-K, Universal Childcare</strong>, y <strong>una educación pública K-12</strong> de calidad en el estado. <strong>Alguien que luchará por una legislación ambiciosa para brindar a las personas la oportunidad de obtener la ciudadanía y permitir que los inmigrantes vivan sus vidas con dignidad. Alguien que luchará por una legislación ambiciosa para poner fin a nuestra crisis climática. Alguien que haga frente a los extremistas que intentan dividirnos. Necesitamos un congresista que pueda unir a la gente para resolver problemas y hacer avanzar a Rhode Island. Necesitamos un congresista que esté listo desde el primer día y yo soy esa persona.</strong>
          </p>
          <p>
            <strong>Nuestro camino hacia la victoria:</strong> nuestra campaña está profundamente arraigada en la comunidad. Creo en mantenerme por lejos de la confrontación, poner a las personas por encima de la política, unir a las personas y poner a las personas primero. Contamos con un gran equipo experimentado que ha ganado más de 50 carreras del Congreso en todo el país. Lea sobre ellos <a href="https://us4.campaign-archive.com/?u=627d403ab5671dae64834341c&id=d61951a3a6" target="_blank">aquí</a>. <strong>He ganado elecciones antes en uno de los distritos más competitivos del East Side a pesar de haber sido contado, gastado más y superado en recaudación 4 a 1. En esta contienda, tenemos una sólida base de apoyo y hemos recaudado más de $150.000 a través de pequeños, simpatizantes de base en el distrito de todo el estado</strong> (no aceptamos dinero del Súper PAC, dinero del PAC corporativo, o dinero de combustibles fósiles, por lo que solo estamos en deuda con la gente y podemos luchar para mantener las grandes petroleras, el cabildeo de las armas, y las grandes responsable farmacéutico). Nuestra historia/mensaje resuena en una coalición amplia y diversa de residentes/votantes que desean enviar un legislador eficaz y una voz diferente al Congreso.
          </p>
          <p>
            Con la elección a la vuelta de la esquina, espero conectarme con usted y continuar escuchando sus esperanzas, sueños, pensamientos, e inquietudes. No dude en comunicarse conmigo al 401-227-5056 o <a href="mailto:info@johngforcongress.com">info@johngforcongress.com</a> con cualquier pregunta o inquietud que tenga. Sería mi mayor honor contar con su apoyo.
          </p>
          <p>
            <strong>Tenga en cuenta que ahora puede votar en su Alcaldía, y le pido su consideración y su apoyo. Espero poder ganar su voto.</strong>
          </p>
          <p>
            Muchas gracias,<br />
            Juan Goncalves
          </p>
          <p>
            <a href="/">https://www.johngforcongress.com/</a>
          </p>
          <p>
            <a href="https://secure.actblue.com/donate/john-goncalves-website-donate?refcode=website-header-nav-desktop-tablet" target="_blank"><strong>Por favor haga una contribución</strong></a><br />
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSeOc1a0EdyhHPIxRUrRYcLojn9dBx1UFwAUhj7fF8Hic3ow2w/viewform" target="_blank"><strong>Solicite un letrero de jardín</strong></a><br />
            <a href="https://www.youtube.com/watch?v=4aX1kc4sEBs" target="_blank">Vídeo de la campaña</a><br />
            <a href="https://www.instagram.com/johngpvd/" target="_blank">Por favor síguenos en Instagram</a><br />
            Por favor síguenos en <a href="https://twitter.com/johngpvd" target="_blank">Twitter</a>/<a href="https://www.facebook.com/JohnGForCongress" target="_blank">Facebook</a>
          </p>
		    </div>

        <div className="letter-section" id="portugues">
          <h2><strong>Português</strong></h2>
          <p>
            Querido Neighbor,
          </p>
          <p>
            Caso você não tenha ouvido minha história, sou um dos candidatos à sucessão do deputado Cicilline. <strong>Resumindo:</strong> sou vereador no East Side de Providence e sou um professor com uma história única que está concorrendo ao Congresso para colocar as pessoas em primeiro lugar.
          </p>
          <p>
            <strong>Como residente do Primeiro Distrito Congressional por quase 30 anos, conheço muito bem as lutas que nossa comunidade enfrenta:</strong> o aumento do custo de vida, moradia e saúde, e dificuldades em escolas públicas de ensino fundamental e médio que não estão beneficiando as famílias trabalhadoras e idosos da nossa comunidade. As pessoas estão preocupadas com a educação, estão preocupadas com a nossa economia, estão preocupadas com a saúde. Eles estão preocupados com os direitos reprodutivos, estão preocupados com nossa crise climática. As pessoas também querem uma chance de sucesso, uma oportunidade de ter uma qualidade de vida decente e bons Bairros para morar e sustentar suas famílias.
          </p>
          <p>
            <strong>Washington não tem trabalhado para nós. Precisamos de um guerreiro em Washington que defenda as famílias trabalhadoras e é por isso que estou nessa luta.</strong>
          </p>
          <p>
            Este trabalho não é teórico para mim. É pessoal para mim: os pais da minha mãe morreram quando ela era criança. Ela veio para este país sem nada em seu nome, mas queria uma vida melhor para seus filhos. Quando eu tinha doze anos, minha mãe implorou ao então prefeito Cicilline que me permitisse ir para a melhor escola pública de ensino médio de Providence, o que mudou minha trajetória para sempre. Quando não tínhamos dinheiro para comprar um carro, ela ia e voltava da escola comigo no ônibus RIPTA para garantir que eu tivesse a melhor educação.
          </p>
          <p>
            Frequentei as Escolas Públicas de Providence do K-8 e a Wheeler School para o ensino médio. Apesar de ter crescido na pobreza em uma família monoparental, minha resiliência inabalável e paixão por aprender acabaram me levando a me formar com um <strong>B.A. pela Brown University e mestrado pela Brown University</strong>.
          </p>
          <p>
            <strong>A história de luta e resiliência aqui em Rhode Island é a minha história. Minha experiência vivida também é o motivo pelo qual realmente acredito que seu código postal não deve determinar seu destino. É também por isso que acredito que todos são importantes e todos contam.</strong>
          </p>
          <p>
            <strong>Como vereador da cidade por dois mandatos,</strong> representando os bairros de East Side e Downtown na cidade de Providence, que é o centro econômico e residencial de mais rápido crescimento no estado &mdash; tenho sido responsivo, confiável, e eficaz. Eu mantenho prefeituras mensais, <a href="/issues/improve-quality-of-life/plan">criei um plano robusto de qualidade de vida</a>, <strong>resolvi milhares e milhares de problemas de serviço constituinte</strong>, e enviei quase 3 milhões de e-mails para meus constituintes (aqui está um exemplo do <a href="https://us4.campaign-archive.com/?u=627d403ab5671dae64834341c&id=551db3ddfe" target="_blank">boletim informativo</a> do meu bairro que fornece informações sobre minha liderança /complexidade do meu trabalho). <strong>Escrevi e aprovei inúmeras leis</strong> relacionadas às questões que nos preocupam e, assim como o congressista Cicilline, que passou do nível municipal para o nível federal, <strong>estou pronto para trazer minhas raízes e energia comunitária para Washington</strong>.
          </p>
          <p>
            <strong>Também sou um professor apaixonado pelo ensino na Escola do Wheeler em Providence e sou professor por quase uma década.</strong> Fui motivado a concorrer porque estou cansado de ter que ensinar meus alunos sobre exercícios de tiro ativo em vez de multiplicação e divisão. Também quero que todas as crianças tenham sucesso e possam ter um futuro onde possam ter água limpa e ar para respirar, possam pagar por moradia e tenham oportunidades econômicas robustas neste estado.
          </p>
          <p>
            A razão pela qual defendo com unhas e dentes os meus constituintes é porque este trabalho não é teórico para mim. Como uma pessoa que cresceu na pobreza, sei como é ir para a cama com fome. Como alguém que já lidou com a discriminação, também sei como é e só quero um futuro mais brilhante e igualitário para nossos filhos, famílias, e idosos.
          </p>
          <p>
            <strong>Também acredito que poder, dinheiro e influência não devem ser um pré-requisito para poder servir significativamente nossa comunidade no Congresso.</strong>
          </p>
          <p>
            Para concretizar a visão que queremos para Rhode Island, precisamos de um congressista que priorize as necessidades de nossa comunidade e coloque a comunidade em primeiro lugar sobre os interesses especiais. <strong>Alguém que lutará pela Previdência Social e pelo Medicare. Alguém que lutará pelos melhores serviços constituintes de alta qualidade. Alguém que lutará pela Universal Pre-K, Universal Child Care</strong>, e <strong>uma educação pública K-12 de qualidade</strong> no estado. <strong>Alguém que lutará por uma legislação ambiciosa para acabar com nossa crise climática. Alguém que enfrentará os extremistas que tentam nos dividir. Precisamos de um congressista que possa reunir as pessoas para resolver problemas e levar Rhode Island adiante. Precisamos de um congressista que esteja pronto no primeiro dia e eu sou essa pessoa.</strong>
          </p>
          <p>
            <strong>Nosso caminho para a vitória:</strong> nossa campanha está profundamente enraizada na comunidade. Acredito em ficar longe de confrontacoes, colocando as pessoas acima da política, reunindo as pessoas e colocando as pessoas em primeiro lugar. Temos uma equipe excelente e experiente que venceu mais de 50 corridas do Congresso em todo o país. Leia sobre eles <a href="https://us4.campaign-archive.com/?u=627d403ab5671dae64834341c&id=d61951a3a6" target="_blank">aqui</a>. <strong>Já ganhei eleições antes em um dos distritos mais competitivos do East Side, apesar de ter sido contado, gasto demais e superado por 4 a 1. Nesta corrida, temos uma forte base de apoio e arrecadamos mais de $150.000 por meio de pequenas, apoiadores de base no distrito de todo o estado</strong> (não estamos aceitando nenhum dinheiro do Super PAC, dinheiro do PAC corporativo, ou dinheiro de combustível fóssil, portanto, devemos apenas ao povo e podemos lutar para manter o grande petróleo, o lobby das armas, e o grande farmacêutico responsável). Nossa história/mensagem ressoa com uma coalizão ampla e diversificada de residentes/eleitores que desejam enviar um legislador eficaz e um tipo diferente de voz ao Congresso.
          </p>
          <p>
            Com a eleição chegando, estou ansioso para me conectar contigo e continuar a ouvir suas esperanças, sonhos, pensamentos, e preocupações. Não hesite em entrar em contato comigo pelo telefone 401-227-5056 ou <a href="mailto:info@johngforcongress.com">info@johngforcongress.com</a> com qualquer dúvida ou preocupação. Seria minha maior honra ter seu apoio.
          </p>
          <p>
            <strong>Saiba que agora você pode votar em sua vila/prefeitura e peço sua consideração e seu apoio. Espero poder ganhar seu voto antecipado.</strong>
          </p>
          <p>
            Muito obrigado,<br />
            João Gonçalves
          </p>
          <p>
            <a href="/">https://www.johngforcongress.com/</a>
          </p>
          <p>
            <a href="https://secure.actblue.com/donate/john-goncalves-website-donate?refcode=website-header-nav-desktop-tablet" target="_blank"><strong>Por favor faça uma contribuição</strong></a><br />
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSeOc1a0EdyhHPIxRUrRYcLojn9dBx1UFwAUhj7fF8Hic3ow2w/viewform" target="_blank"><strong>Solicite um sinal de jardim</strong></a><br />
            <a href="https://www.youtube.com/watch?v=4aX1kc4sEBs" target="_blank">Vídeo da campanha</a><br />
            <a href="https://www.instagram.com/johngpvd/" target="_blank">Por favor siga-nos no Instagram</a><br />
            Por favor siga-nos no <a href="https://twitter.com/johngpvd" target="_blank">Twitter</a>/<a href="https://www.facebook.com/JohnGForCongress" target="_blank">Facebook</a>
          </p>
		    </div>
      </div>
    </div>
  </Layout>
)

export default LetterFromJohnPage
